import React, { useCallback, useMemo } from 'react';
import { CampaignTypes } from 'api';
import { colors } from 'theme/constants';
import {
  formatCurrency,
  formatNumber,
  formatPercent,
} from 'helpers/formatters';
import CardTable, { ComparisonTableItems } from './CardTable';

interface Props {
  campaign: CampaignTypes.ItemData;
  comparedCampaign?: CampaignTypes.ItemData;
}

const ComparisonTable = (props: Props): JSX.Element | null => {
  const { campaign, comparedCampaign } = props;

  const getTrend = useCallback(
    (key: keyof CampaignTypes.ItemData) =>
      (campaign[key] || 0) > (comparedCampaign ? comparedCampaign[key] : 0)
        ? 'up'
        : 'down',
    [campaign, comparedCampaign],
  );

  const tableFields: ComparisonTableItems<CampaignTypes.ItemData> = useMemo(
    () => [
      {
        key: 'roi_target',
        label: 'ROI Target',
        prevValue: formatPercent(comparedCampaign?.roi_target || 0),
        currentValue: formatPercent(campaign.roi_target || 0),
        trend: getTrend('roi_target'),
      },
      {
        key: 'budget_target',
        label: 'Budget Target',
        prevValue: formatCurrency(comparedCampaign?.budget_target || 0),
        currentValue: formatCurrency(campaign.budget_target || 0),
        trend: getTrend('budget_target'),
      },
      {
        key: 'budget_actual',
        label: 'Budget Actual',
        prevValue: formatCurrency(comparedCampaign?.budget_actual || 0),
        currentValue: formatCurrency(campaign.budget_actual || 0),
        trend: getTrend('budget_actual'),
      },

      {
        key: 'budget_traffic_light',
        label: 'Budget Traffic Light',
        prevValue: formatPercent(comparedCampaign?.budget_traffic_light || 0),
        currentValue: formatPercent(campaign.budget_traffic_light || 0),
        trend: getTrend('budget_traffic_light'),
      },
      {
        key: 'budget_running_forecast',
        label: 'Budget Running Forecast',
        prevValue: formatCurrency(
          comparedCampaign?.budget_running_forecast || 0,
        ),
        currentValue: formatCurrency(campaign.budget_running_forecast || 0),
        trend: getTrend('budget_running_forecast'),
      },
      {
        key: 'revenue_target',
        label: 'Revenue Target',
        prevValue: formatCurrency(comparedCampaign?.revenue_target || 0),
        currentValue: formatCurrency(campaign.revenue_target || 0),
        trend: getTrend('revenue_target'),
      },
      {
        key: 'revenue_actual',
        label: 'Revenue Actual',
        prevValue: formatCurrency(comparedCampaign?.revenue_actual || 0),
        currentValue: formatCurrency(campaign.revenue_actual || 0),
        trend: getTrend('revenue_actual'),
      },
      {
        key: 'revenue_traffic_light',
        label: 'Revenue Traffic Light',
        prevValue: formatPercent(comparedCampaign?.revenue_traffic_light || 0),
        currentValue: formatPercent(campaign.revenue_traffic_light || 0),
        trend: getTrend('revenue_traffic_light'),
      },
      {
        key: 'revenue_running_forecast',
        label: 'Revenue Running Forecast',
        prevValue: formatCurrency(
          comparedCampaign?.revenue_running_forecast || 0,
        ),
        currentValue: formatCurrency(campaign.revenue_running_forecast || 0),
        trend: getTrend('revenue_running_forecast'),
      },
      {
        key: 'aov_target',
        label: 'AOV Target',
        prevValue: formatCurrency(comparedCampaign?.aov_target || 0),
        currentValue: formatCurrency(campaign.aov_target || 0),
        trend: getTrend('aov_target'),
      },
      {
        key: 'aov_actual',
        label: 'AOV Actual',
        prevValue: formatCurrency(comparedCampaign?.aov_actual || 0),
        currentValue: formatCurrency(campaign.aov_actual || 0),
        trend: getTrend('aov_actual'),
      },
      {
        key: 'new_customers_actual',
        label: 'New Customers Actual',
        prevValue: formatNumber(comparedCampaign?.new_customers_actual || 0),
        currentValue: formatNumber(campaign.new_customers_actual || 0),
        trend: getTrend('new_customers_actual'),
      },
      {
        key: 'new_customers_target',
        label: 'New Customers Target',
        prevValue: comparedCampaign?.new_customers_target || 0,
        currentValue: campaign.new_customers_target || 0,
        trend: getTrend('new_customers_target'),
      },
      {
        key: 'orders_nb_actual',
        label: 'Orders Number',
        prevValue: comparedCampaign?.orders_nb_actual || 0,
        currentValue: campaign.orders_nb_actual || 0,
        trend: getTrend('orders_nb_actual'),
      },
      {
        key: 'deals_nb',
        label: 'Deals Number',
        prevValue: comparedCampaign?.deals_nb || 0,
        currentValue: campaign.deals_nb || 0,
        trend: getTrend('deals_nb'),
      },
      {
        key: 'influencers_nb',
        label: 'Influencers Number',
        prevValue: comparedCampaign?.influencers_nb || 0,
        currentValue: campaign.influencers_nb || 0,
        trend: getTrend('influencers_nb'),
      },
      {
        key: 'reach_total',
        label: 'Total Reach',
        prevValue: comparedCampaign?.reach_total || 0,
        currentValue: campaign.reach_total || 0,
        trend: getTrend('reach_total'),
      },
      {
        key: 'customer_reactivation_ratio',
        label: 'Reactivation rate',
        prevValue: formatPercent(
          comparedCampaign?.customer_reactivation_ratio || 0,
        ),
        currentValue: formatPercent(campaign.customer_reactivation_ratio || 0),
        trend: getTrend('customer_reactivation_ratio'),
      },
    ],
    [campaign, comparedCampaign, getTrend],
  );

  return (
    <CardTable
      color={colors.orange}
      title="Campaigns metrics comparison"
      data={tableFields}
      comparedTitle={comparedCampaign?.name}
      currentTitle={campaign?.name}
      loading={!comparedCampaign}
    />
  );
};

export default ComparisonTable;
