import apiClient from '../apiClient';
import InfluencerTypes from './types';
import { AutocompleteSearchItem } from '../types/common';

const endpoints = {
  autocompleteSearch: 'influencers/autocomplete',
  getList: 'influencers/filter',
  getListAutoUpdated: 'influencers//get_autoupdated',
  getBrands: 'influencers/get_brands',
  getById: (id: string) => `influencers/${id}`,
  getPotentialDetailsById: (id: string) =>
    `influencers/${id}/fetch_potential_details`,
  create: 'influencers',
  update: (id: string) => `influencers/${id}`,
  remove: (id: string) => `influencers/${id}`,
  getDealsChartById: (id: string) => `influencers/${id}/deal_chart_details`,
  getInsightsChartById: (id: string) =>
    `influencers/${id}/insights_chart_details`,
  bulkBrandMentions: 'influencers/bulk_brand_mentions',
  topInfluencersList: 'influencers/top_influencers_list',
  getLogbook: (id: string) => `influencers/${id}/logbook`,
};

const autocompleteSearch = (
  params: InfluencerTypes.AutocompleteSearchParams,
): InfluencerTypes.AutocompleteSearchResponse => {
  let json;
  if (typeof params === 'string') {
    json = {
      global_search_string: params || 'a',
      limit: 10,
    };
  } else {
    json = {
      ...params,
      global_search_string: params.global_search_string || 'a',
      limit: 10,
    };
  }
  return apiClient
    .post(endpoints.autocompleteSearch, {
      json,
    })
    .then((response) => response.json());
};

const getList = (
  params: InfluencerTypes.GetListParams,
): InfluencerTypes.ListResponse =>
  apiClient
    .post(endpoints.getList, {
      json: params,
    })
    .then((response) => response.json());

const getListAutoUpdated = (): InfluencerTypes.ListAutoUpdatedResponse =>
  apiClient
    .post(endpoints.getListAutoUpdated)
    .then((response) => response.json())
    .then((res) => ({
      data: res as InfluencerTypes.ListAutoUpdatedData,
      total: (res as []).length,
    }));

const getListCondensed = (
  params: InfluencerTypes.GetListParams,
): InfluencerTypes.ListCondensedResponse =>
  apiClient
    .post(endpoints.getList, {
      json: {
        ...params,
        response_format: 'condensed',
      },
    })
    .then((response) => response.json());

const getById = (id: string): InfluencerTypes.ItemResponse =>
  apiClient.get(endpoints.getById(id)).then((response) => response.json());

const getPotentialDetailsById = (
  id: string,
): InfluencerTypes.PotentialDetailsResponse =>
  apiClient
    .get(endpoints.getPotentialDetailsById(id))
    .then((response) => response.json());

const processError = (response: Response): InfluencerTypes.ItemResponse =>
  response.json().then((r: any) => {
    if ('brand_infos' in r) {
      throw new Error('Failed to update brand info');
    }
    if ('profile_name' in r) {
      throw new Error(
        Array.isArray(r.profile_name)
          ? r.profile_name[0]?.error?.message
          : 'Nickname is already in use',
      );
    }
    throw new Error('Failed to update influencer info');
  });

const create = (
  data: InfluencerTypes.ItemCreateParams,
): InfluencerTypes.ItemResponse =>
  apiClient
    .post(endpoints.create, {
      json: { influencer: data },
      timeout: 300000,
      throwHttpErrors: false,
    })
    .then((response) => {
      if (response.ok) {
        return response.json();
      }

      return processError(response);
    });

const update = (
  id: string,
  data: InfluencerTypes.ItemCreateParams,
): InfluencerTypes.ItemResponse =>
  apiClient
    .put(endpoints.update(id), {
      json: { influencer: data },
      throwHttpErrors: false,
    })
    .then((response) => {
      if (response.ok) {
        return response.json();
      }

      return processError(response);
    });

const remove = (id: string): InfluencerTypes.ItemResponse =>
  apiClient
    .put(endpoints.remove(id), { json: { influencer: { is_deleted: true } } })
    .then((response) => response.json());

const getDealsChartById = (
  id: string,
  type: InfluencerTypes.DealsChartType,
): InfluencerTypes.DealsChartResponse =>
  apiClient
    .post(endpoints.getDealsChartById(id), { json: { chart: type } })
    .then((response) => response.json());

const getInsightsChartById = (
  id: string,
  type: InfluencerTypes.InsightsChartType,
  platformType: InfluencerTypes.InsightsChartPlatformType,
): InfluencerTypes.InsightsChartResponse =>
  apiClient
    .post(endpoints.getInsightsChartById(id), {
      json: { chart: type, platform_type: platformType },
    })
    .then((response) => response.json());

const getBrands = (): Promise<string[]> =>
  apiClient.post(endpoints.getBrands).then((response) => response.json());

const getTags = (query: string): Promise<AutocompleteSearchItem[]> =>
  apiClient
    .post(endpoints.getBrands)
    .then((response) => response.json())
    .then((data) =>
      (data as string[])
        .filter((e) => e.indexOf(query) >= 0)
        .map((e) => ({ _id: { $oid: e }, name: e })),
    );

const bulkBrandMentions = (
  params: InfluencerTypes.BulkBrandMentionParams,
): InfluencerTypes.ListResponse =>
  apiClient
    .post(endpoints.bulkBrandMentions, {
      json: params,
      throwHttpErrors: false,
    })
    .then((response) => {
      if (!response.ok) {
        return response.json().then((data: any) => {
          const msg =
            'message' in data
              ? data.message
              : 'Failed to modify brand mentions list';

          throw new Error(msg);
        });
      }

      return response
        .json()
        .then((data: any) =>
          'message' in data
            ? data.message
            : 'Influencers brand mentions list updated',
        );
    });

const getTopList = (
  params: InfluencerTypes.GetTopInfluencersListParams,
): InfluencerTypes.TopInfluencersListResponse =>
  apiClient
    .post(endpoints.topInfluencersList, {
      json: params,
    })
    .then((response) => response.json())
    .then((res) => {
      const data = res as InfluencerTypes.TopInfluencersListData;

      if (params.sort_by) {
        // @ts-ignore
        const sorted = data.sort((a, b) => {
          if (params.sort_desc) {
            // @ts-ignore
            return (a[params.sort_by] || 0) < (b[params.sort_by] || 0) ? 1 : -1;
          }

          // @ts-ignore
          return (a[params.sort_by] || 0) > (b[params.sort_by] || 0) ? 1 : -1;
        });

        return { total: data.total, data: sorted };
      }

      return data;
    });

const getLogbook = (id: string): InfluencerTypes.LogbookResponse =>
  apiClient.get(endpoints.getLogbook(id)).then((response) => response.json());

export default {
  endpoints,
  autocompleteSearch,
  getList,
  getListCondensed,
  getById,
  create,
  update,
  getDealsChartById,
  getInsightsChartById,
  remove,
  getBrands,
  getTags,
  getListAutoUpdated,
  getPotentialDetailsById,
  bulkBrandMentions,
  getTopList,
  getLogbook,
};
