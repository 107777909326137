import { influencers, InfluencerTypes } from 'api';
import { Logbook } from 'components';

interface Props {
  data: InfluencerTypes.ItemExtended;
}

const LogbookTab = (props: Props): JSX.Element | null => {
  const { data } = props;

  return (
    <Logbook
      queryKey={influencers.endpoints.getLogbook(data._id.$oid)}
      queryFn={() => influencers.getLogbook(data._id.$oid)}
    />
  );
};

export default LogbookTab;
