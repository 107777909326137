import * as React from 'react';
import { Avatar, ListItemAvatar, ListItemText } from '@mui/material';
import { AutocompleteSearchItem } from 'api/types/common';
import { getUserInitials } from 'helpers/common';

interface Props extends React.HTMLAttributes<HTMLLIElement> {
  option: AutocompleteSearchItem;
}

const AutocompleteOption = (props: Props): JSX.Element => {
  const { option, ...rest } = props;

  return (
    <li {...rest}>
      <ListItemAvatar>
        <Avatar
          sx={(theme) => ({
            backgroundColor: theme.palette.primary.light,
          })}
        >
          {getUserInitials(option.name)}
        </Avatar>
      </ListItemAvatar>
      <ListItemText>
        <ListItemText primary={option.name} />
      </ListItemText>
    </li>
  );
};

export default AutocompleteOption;
