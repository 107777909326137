import React, { useEffect, useMemo, useState } from 'react';
import { CardContent, CardHeader, Link, Typography } from '@mui/material';
import { appLinks } from 'routes/links';
import { useQuery } from '@tanstack/react-query';
import { campaigns, CampaignTypes } from 'api';
import { colors } from 'theme/constants';
import { ColorCard } from 'components';
import Search from './Search';

interface Props {
  campaign: CampaignTypes.ItemData;
  compared?: CampaignTypes.ItemData;
  setCompared: React.Dispatch<
    React.SetStateAction<CampaignTypes.ItemData | undefined>
  >;
}

const CampaignComparisonCard = (props: Props): JSX.Element | null => {
  const { campaign, setCompared, compared } = props;

  const [query, setQuery] = useState<string[]>([]);

  const initQueryParams: CampaignTypes.GetListParams = useMemo(
    () => ({
      sort_by: 'start_date',
      sort_desc: true,
      limit: 2,
      page: 1,
      filters: {
        start_date: { $lte: campaign.start_date },
        brands: campaign.brands,
        country_teams: campaign.country_teams,
        type: campaign.type,
      },
    }),
    [campaign],
  );
  const queryParams: CampaignTypes.GetListParams = useMemo(
    () => ({
      sort_by: 'start_date',
      sort_desc: true,
      filters: {
        name: query.length > 0 ? query[0] : undefined,
      },
    }),
    [query],
  );

  const { data } = useQuery(
    [campaigns.endpoints.getList, queryParams, initQueryParams],
    () =>
      campaigns
        .getList(query.length > 0 ? queryParams : initQueryParams)
        .then((res) =>
          res.data.filter((r) => r._id.$oid !== campaign._id.$oid),
        ),
  );

  const prev = useMemo(() => (data ? data[0] : undefined), [data]);

  useEffect(() => {
    if (prev) {
      setCompared(prev);
    }
  }, [prev, setCompared]);

  return (
    <ColorCard color={colors.accent}>
      <CardHeader
        title={
          <Typography variant="h5">
            Comparison of campaign metrics with{' '}
            <Link
              variant="inherit"
              underline="hover"
              href={appLinks.campaigns.details.as(compared?.campaign_id || '')}
              target="_blank"
            >
              {compared?.name}
            </Link>
          </Typography>
        }
      />
      <CardContent>
        <Search query={query} setQuery={setQuery} />
      </CardContent>
    </ColorCard>
  );
};

export default CampaignComparisonCard;
