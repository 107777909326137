import { deals, DealTypes } from 'api';
import { Logbook } from 'components';

interface Props {
  deal: DealTypes.ItemDataExtended;
}

const LogbookTab = (props: Props): JSX.Element | null => {
  const { deal } = props;

  return (
    <Logbook
      queryKey={deals.endpoints.getDealLogbook(deal._id.$oid)}
      queryFn={() => deals.getDealLogbook(deal._id.$oid)}
    />
  );
};

export default LogbookTab;
