import {
  ID,
  ListPaginationParams,
  ListPaginationResponse,
  ListReportData,
  LogbookItem,
} from '../types/common';
import { InfluencerTypes } from '../influencers';
import { BrandsEnum } from '../types/Brands';
import { CountriesEnum } from '../types/Countries';
import { DiscountTypes } from '../discounts';
import { TimelineTypes } from '../timeline';

namespace DealTypes {
  export enum ChannelAffiliateEnum {
    Affiliate = 'Affiliate',
  }
  export enum ChannelB2BEnum {
    Retailers = 'Retailers',
    CorporateGifting = 'Corporate Gifting',
    RetailCampaign = 'Retail Campaign',
    BenefitsPrograms = 'Benefits Programs',
    FlyerPlacements = 'Flyer Placements',
    MarketingActivation = 'Marketing Activation',
    BrandCollaboration = 'Brand Collaboration',
    Dropshipping = 'Dropshipping',
    Others = 'Others',
  }
  export enum ChannelCRMEnum {
    Community = 'Community',
    Flyer = 'Flyer',
    LifecycleAutomation = 'Lifecycle Automation',
    LoyaltyProgram = 'Loyalty Program',
    Newsletter = 'Newsletter',
    OfflineMailing = 'Offline Mailing',
    Reactivation = 'Reactivation',
    ScentCards = 'Scent Cards',
    WebPushNotifications = 'Web Push Notifications',
  }
  export enum ChannelCustomersServiceEnum {
    OffsetOperationalIssue = 'Offset Operational Issue',
    Sales = 'Sales',
  }
  export enum ChannelHrEnum {
    Employees = 'Employees',
    FamilyFriends = 'Family & Friends',
    Investors = 'Investors',
    Partners = 'Partners',
  }
  export enum ChannelInfluencerEnum {
    InstagramStories = 'Instagram Stories',
    InstagramPost = 'Instagram Post',
    InstagramReels = 'Instagram Reels',
    InstagramTVLive = 'Instagram TV / Live',
    InstagramBio = 'Instagram Bio',
    InstagramGuide = 'Instagram Guide',
    BlogPost = 'Blog Post',
    PinterestPost = 'Pinterest Post',
    SnapchatPost = 'Snapchat Post',
    TiktokPost = 'Tiktok Post',
    YoutubePost = 'Youtube Post',
  }
  export enum ChannelInternalEnum {
    Bambuser = 'Bambuser',
    FacebookStories = 'Facebook Stories',
    FacebookPost = 'Facebook Post',
    InstagramPost = 'Instagram Post',
    InstagramBio = 'Instagram Bio',
    InstagramGuide = 'Instagram Guide',
    InstagramReels = 'Instagram Reels',
    InstagramStories = 'Instagram Stories',
    InstagramTVLive = 'Instagram TV / Live',
    PinterestPost = 'Pinterest Post',
    SnapchatPost = 'Snapchat Post',
    TiktokPost = 'Tiktok Post',
    TelegramPost = 'Telegram Post',
    YoutubePost = 'Youtube Post',
  }
  export enum ChannelOrganicEnum {
    Organic = 'Organic',
  }
  export enum ChannelLocalPromoEnum {
    OutOfHome = 'Out of home',
    PopUpStores = 'Pop-up Stores',
  }
  export enum ChannelPerformanceMarketingEnum {
    FacebookAds = 'Facebook Ads',
    GoogleAds = 'Google Ads',
    MicrosoftAds = 'Microsoft Ads',
    PinterestAds = 'Pinterest Ads',
    SnapchatAds = 'Snapchat Ads',
    TikTokAds = 'TikTok Ads',
    YoutubeAds = 'Youtube Ads',
  }
  export enum ChannelPrEnum {
    MagazineOffline = 'Magazine Offline',
    MagazineOnline = 'Magazine Online',
  }
  export enum ChannelSamplingEnum {
    Sachets = 'Sachets',
    TeaBags = 'Tea Bags',
    VialCards = 'Vial Cards',
    ScratchCards = 'Scratch Cards',
  }
  export enum ChannelWebsiteEnum {
    AnnouncementBar = 'Announcement Bar',
    // NewsletterSubscription = 'Newsletter Subscription',
    // NewProductSubscription = 'New Product Subscription',
    // BackInStockSubscription = 'Back in Stock Subscription',
    // WishlistSubscriptions = 'Wishlist Subscriptions',
  }
  export enum ChannelMarketplaceEnum {
    Amazon = 'Amazon',
  }
  export enum ChannelCoCreation {
    UsageRights = 'Usage Rights',
    InternalExpenses = 'Internal Expenses',
    ProductCommission = 'Product Commission',
    InstagramStories = 'Instagram Stories',
    InstagramBio = 'Instagram Bio',
    InstagramPost = 'Instagram Post',
    InstagramTVLive = 'Instagram TV / Live',
    YoutubePost = 'Youtube Post',
    TiktokPost = 'Tiktok Post',
  }

  export type ChannelEnum =
    | ChannelInfluencerEnum
    | ChannelPerformanceMarketingEnum
    | ChannelCRMEnum
    | ChannelWebsiteEnum
    | ChannelAffiliateEnum
    | ChannelInternalEnum
    | ChannelHrEnum
    | ChannelSamplingEnum
    | ChannelOrganicEnum
    | ChannelCustomersServiceEnum
    | ChannelB2BEnum
    | ChannelPrEnum
    | ChannelLocalPromoEnum
    | ChannelMarketplaceEnum
    | ChannelCoCreation;

  export enum StrategiesEnum {
    influencers = 'Influencers',
    performanceMarketing = 'Performance Marketing',
    CRM = 'CRM',
    website = 'Website',
    affiliate = 'Affiliate',
    internal = 'Internal',
    HR = 'HR',
    sampling = 'Sampling',
    organic = 'Organic',
    customerService = 'Customer Service',
    B2B = 'B2B',
    PR = 'PR',
    localPromotion = 'Local Promotion',
    marketplace = 'Marketplace',
    coCreation = 'Co-creation',
  }
  export enum StatusEnum {
    OnRadar = 'On radar',
    Pending = 'Pending',
    Scheduled = 'Scheduled',
    Missing = 'Missing',
    Posted = 'Posted',
    Finished = 'Finished',
  }
  export enum BudgetTypeEnum {
    fixedBudget = 'Fixed budget',
    commissionRate = 'Commission rate',
    fixedAndCommission = 'Fixed budget + Commission rate',
  }
  export enum TypeEnum {
    LongTerm = 'Long-term',
    NewDeal = 'New deal',
    ReTest = 'Re-test',
    NonInfluencer = 'Non-influencer',
    UGC = 'UGC',
  }
  export enum FocusEnum {
    Sales = 'Sales',
    Awareness = 'Awareness',
    Content = 'Content',
  }
  export enum StoriesTagEnum {
    AvaMayDE = 'avamay_de',
    AvaMayFR = 'avamay_fr',
    AvaMayIT = 'avamay.it',
    AvaMayPL = 'avamay_pl',
    FayntDE = 'faynt_de',
    FayntFR = 'faynt_fr',
    FayntIT = 'faynt.it',
    FayntPL = 'faynt_pl',
    ClubNoeDE = 'clubnoe',
    ClubNoeFR = 'clubnoe_fr',
    ClubNoeIT = 'clubnoe.it',
  }

  export type Currency = 'EUR' | 'PLN';

  export interface PreChecklist {
    briefing_sent?: boolean;
    code_created?: boolean;
    content_submitted_for_approval?: boolean;
    contract_sent?: boolean;
    contract_signed?: boolean;
    invoice_received?: boolean;
    permission_to_reuse_content?: boolean;
    product_sent?: boolean;
    insights_requested?: boolean;
  }

  export interface ContentChecklist {
    '4_6_sequences'?: boolean;
    action_followed?: boolean;
    aesthetic?: boolean;
    brand_tagged?: boolean;
    coupon_code_visible?: boolean;
    face_story?: boolean;
    influencer_speaking?: boolean;
    lighting?: boolean;
    mix_and_match?: boolean;
    product_included_and_applied?: boolean;
    resasons_to_love?: boolean;
    showing_more_than_1_product?: boolean;
    subtitles?: boolean;
    swipe_up_included?: boolean;
    urgency_of_code?: boolean;
    visibility_of_logo?: boolean;
  }

  export enum BrandProductEnum {
    AvaAndMay = 'am',
    Faynt = 'faynt',
    ClubNoe = 'cn',
  }

  export type BrandProductItem = {
    [key in BrandProductEnum]: number;
  };

  export interface Metric {
    _id: ID;
    brand_product_cm3: BrandProductItem[];
    brand_product_quantity: BrandProductItem[];
    brand_product_revenue: BrandProductItem[];
    budget_actual: number;
    budget_actual_lc: number;
    budget_potential: number;
    budget_potential_lc: number;
    cm1_actual: number;
    cm2_actual: number;
    date: string;
    orders_nb_actual: number;
    revenue_actual: number;
    revenue_potential: number;
  }

  export interface Post {
    _id: ID;
    attachments: string[];
    caption: any;
    channel: DealTypes.ChannelEnum;
    clicks_nb: any;
    comment_to_like: any;
    comments_nb: any;
    created_at: string;
    date: any;
    day_part: any;
    deal_id: ID;
    dislikes_nb: any;
    engagement_rate_relative: any;
    influencer_profile_name: string;
    instagram_post_id: any;
    instagram_reel_id: any;
    instagram_story_id: any;
    instagram_tv_id: any;
    likes_nb: any;
    reach: any;
    reminded_deal_id: any;
    sequence_ids: any;
    shares_nb: any;
    shortcode: any;
    source_url: any;
    stories_tag?: StoriesTagEnum;
    tags: any[];
    tiktok_post_id: any;
    updated_at: string;
    views_nb: any;
  }

  export interface ItemCreateParams {
    brand: BrandsEnum;
    channel: ChannelEnum;
    country_team: CountriesEnum;
    focus: FocusEnum;
    influencer_id: string;
    lead: string;
    shop_id: string;
    start_date: string;
    status: StatusEnum;
    stories_tag?: StoriesTagEnum;
    strategy: StrategiesEnum;
    type: TypeEnum;

    budget_type: BudgetTypeEnum;
    currency: Currency;
    budget_potential_lc?: number;
    roi_potential?: number;

    revenue_potential_lc?: number;
    budget_fixed?: number;
    commission?: number;

    url_auto_redemption?: boolean;
    end_date?: string | null;
    reminder_date?: string | null;
    product_categories?: string[];
    products?: string[];
    url_landing?: string;
    short_url_utm?: string;
    campaigns?: {
      _id: ID;
      name: string;
    }[];
    campaign_ids?: string[];

    coupon_code?: string | null;
    coupon_description?: string | null;
    discount_type?: DiscountTypes.DetailsEnum | null;
    discount_description?: string | null;
    discount_value?: number | null;
    free_gift_products?: string[];
    free_gift_product_categories?: string[];

    actual_reach?: number;
    avg_overall_reach?: number; // auto prefill from influencer data
    impressions?: number;
    clicks?: number;

    post?: Post;
  }

  export interface BulkItemCreateParams {
    deals: ItemCreateParams[];
  }

  export interface BulkChangeStatusParams {
    deal_ids: string[];
    status?: StatusEnum;
    is_deleted?: boolean;
  }

  export interface BulkCampaignAssignParams {
    deal_ids: string[];
    campaign_ids?: string[];
    remove_campaigns?: boolean;
  }

  export interface ItemData
    extends Omit<
      ItemCreateParams,
      'influencer_id' | 'shop_id' | 'campaign_ids'
    > {
    _id: ID;
    influencer_id: ID;
    shop_id: ID;
    campaign_ids?: ID[];
    bookmark_id?: string | null;
    autocreated: any;
    avg_basket_actual: number;
    budget_actual: number;
    budget_actual_lc: number;
    budget_potential: number;
    conversion_rate_pc: number;
    checklist_content: ContentChecklist;
    cm1_actual: any;
    cm3_actual: any;
    cm3_actual_pc: any;
    comments: any;
    coupon_prefix: any;
    cpm_actual: any;
    cpo_actual: any;
    created_at: string;
    customers: {
      new: number;
      all: number;
    };
    deal_id: string;
    gross_profit_actual: number;
    gross_profit_potential: number;
    influencer_profile_name: string;
    is_deleted: boolean;
    metrics: Metric[];
    metrics_updated_at: string;
    new_customers_ratio: any;
    orders_nb_actual: number;
    post_checklist: never;
    post_scraped_at: any;
    pre_checklist: PreChecklist;
    reminder_post_scraped_at: any;
    revenue_actual: number;
    revenue_potential: number;
    roi_actual: number;
    start_week_day: string;
    updated_at: string;
    url_utm: string;
    influencer: Pick<
      InfluencerTypes.ItemData,
      'influencer_id' | 'profile_name' | 'profile_picture_url'
    > & { gender: InfluencerTypes.GenderEnum };
    reminder_post?: Post;
    timeline_events: (TimelineTypes.ItemData & {
      deal_id: ID;
      model: TimelineTypes.ModelEnum.Deal;
    })[];
    story_links?: string[];
    is_posted_url_valid?: boolean;
    customer_reactivation_ratio: number | null;
  }

  export interface ItemDataExtended extends ItemData {
    brand_product_cm3: BrandProductItem[];
    brand_product_quantity: BrandProductItem[];
    brand_product_revenue: BrandProductItem[];
  }

  export type ItemResponse = Promise<ItemDataExtended>;

  export type ItemCondensed = Pick<
    ItemData,
    | 'brand'
    | 'deal_id'
    | 'influencer_id'
    | 'influencer_profile_name'
    | 'start_date'
    | 'end_date'
    | 'status'
    | '_id'
  >;

  export type ListCondensedData = ListPaginationResponse<ItemCondensed>;

  export type ListCondensedResponse = Promise<ListCondensedData>;

  export interface ListData extends ListPaginationResponse<ItemData> {
    report: ListReportData;
  }

  export type ListResponse = Promise<ListData>;

  export type ItemDataWithAttachments = ItemData & { attachments: string[] };

  export interface ListWithAttachmentsData
    extends ListPaginationResponse<ItemDataWithAttachments> {
    report: ListReportData;
  }

  export type ListWithAttachmentsResponse = Promise<ListWithAttachmentsData>;

  export interface GetListParams extends ListPaginationParams {
    response_format?: string | 'condensed';
    filters: {
      // global
      country_team?: string;
      brand?: string;
      // general
      influencer_id?: string;
      'influencer.profile_name'?: string[];
      lead?: string[];
      coupon_code?: string[] | null;
      'campaign.name'?: string[];
      campaign_ids?: string;
      status?: StatusEnum[];
      strategy?: string[];
      channel?: string[];
      budget_type?: string[];
      'influencer.vertical_primary'?: string[];
      start_date?: {
        $gte: string;
        $lte: string;
      };
      start_week_day?: string;
      coupon_description?: string;
      reminder_date?: boolean;
      show_bookmarks?: boolean;
      type?: TypeEnum[];
      discount_value?: {
        $gte?: string;
        $lte?: string;
      };
      roi_potential?: {
        $gte?: string;
        $lte?: string;
      };
      revenue_potential?: {
        $gte?: string;
        $lte?: string;
      };
      budget_potential?: {
        $gte?: string;
        $lte?: string;
      };
    };
  }

  export interface PipelinePreparationItem {
    _id: string;
    currency: string;
    customers: {
      all: number;
      new: number;
    };
    post_checklist: never;
    pre_checklist: PreChecklist;
    product_categories: any[];
    status: string;
    brand: string;
    channel: string;
    country_team: string;
    coupon_code: string;
    coupon_description: string;
    discount_type?: DiscountTypes.DetailsEnum;
    discount_value: any;
    focus: string;
    influencer_id: string;
    lead: string;
    start_date: string;
    stories_tag: string;
    strategy: string;
    type: string;
    free_gift_product_categories: any[];
    free_gift_products: any[];
    deal_id: string;
    end_date: string;
    influencer_profile_name: string;
    updated_at: string;
    created_at: string;
    start_week_day: string;
    new_customers_ratio: number;
    avg_basket_actual: number;
    budget_actual: number;
    budget_actual_lc: number;
    budget_potential: number;
    budget_potential_lc: number;
    budget_potential_lc_ts: {
      date: string;
      value: number;
    }[];
    budget_potential_ts: {
      date: string;
      value: number;
    }[];
    cpm_actual: any;
    cpo_actual: number;
    gross_profit_actual: number;
    metrics_updated_at: string;
    orders_nb_actual: number;
    revenue_actual: number;
    revenue_actual_ts: {
      date: string;
      value: number;
    }[];
    revenue_potential: number;
    revenue_potential_lc: number;
    revenue_potential_ts: {
      date: string;
      value: number;
    }[];
    roi_actual: number;
    roi_potential: number;
    budget_actual_lc_ts: {
      date: string;
      value: number;
    }[];
    budget_actual_ts: {
      date: string;
      value: number;
    }[];
    gross_profit_potential: number;
    orders_nb_actual_ts: {
      date: string;
      value: number;
    }[];
    metrics: {
      _id: string;
      budget_actual: number;
      budget_actual_lc: number;
      budget_potential: number;
      budget_potential_lc: number;
      orders_nb_actual: number;
      revenue_actual: number;
      revenue_potential: number;
      date: string;
      cm1_actual: number;
    }[];
    budget_fixed: number;
    checklist_content: ContentChecklist;
    cm1_actual: number;
    url_auto_redemption: boolean;
    cm3_actual: number;
    cm3_actual_pc: number;
    budget_type: BudgetTypeEnum;
    shop_id: string;
    campaign_ids?: ID[];
    url_utm?: undefined;
    commission?: undefined;
    reminder_date?: undefined;
    discount_description?: undefined;
    url_landing?: undefined;
    post_scraped_at?: undefined;
    short_url_utm?: undefined;
    decision_made_by_id?: undefined;
    posting_month?: undefined;
    coupon_prefix?: undefined;
    products?: undefined;
    invoice_number?: undefined;
    insta_stories_sequences?: undefined;
  }

  export enum AttachmentTypeEnum {
    Briefing = 'briefing',
    Invoice = 'invoice',
    Contract = 'contract',
  }

  export interface AttachmentItem {
    _id: ID;
    created_at: string;
    deal_id: ID;
    number: string;
    updated_at: string;
    attachment: {
      file: string;
      name: string;
    };
  }

  export type AttachmentData = AttachmentItem | {};

  export type AttachmentResponse = Promise<AttachmentData>;

  export interface DealProductsItem {
    brand: 'cn' | 'am' | 'faynt';
    product_category: string;
    product_title: string;
    quantity: number;
    cm3_euro: number;
    cm3_pct: number;
    revenue_net: number;
  }

  export interface DealProductsData {
    product_absolute_quantity: DealProductsItem[];
  }

  export type DealProductsResponse = Promise<DealProductsData>;

  export type LogbookData = LogbookItem<ItemData>[];

  export type LogbookResponse = Promise<LogbookData>;
}

export default DealTypes;
