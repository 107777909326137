import { useQuery } from '@tanstack/react-query';
import { CardContent, CardHeader, Skeleton } from '@mui/material';
import { ColorCard, ErrorBoundary } from 'components';
import { colors } from 'theme/constants';
import { campaigns, CampaignTypes } from 'api';
import CardTable from './CardTable';

interface Props {
  campaign: CampaignTypes.ItemData;
  compared: CampaignTypes.ItemData;
}

const ChannelPerformance = (props: Props): JSX.Element | null => {
  const { campaign, compared } = props;

  const paramsCurrent = {
    filters: {
      campaign_ids: [campaign._id.$oid],
    },
    period: {
      start_date: campaign.start_date,
      end_date: campaign.end_date,
    },
  };

  const paramsCompared = {
    filters: {
      campaign_ids: [compared._id.$oid],
    },
    period: {
      start_date: compared.start_date,
      end_date: compared.end_date,
    },
  };

  const { data, error } = useQuery(
    [campaigns.endpoints.performanceInsightsVar, paramsCurrent],
    () => campaigns.getPerformanceInsightsVar(paramsCurrent),
  );

  const { data: comparedData, error: error2 } = useQuery(
    [campaigns.endpoints.performanceInsightsVar, paramsCompared],
    () => campaigns.getPerformanceInsightsVar(paramsCompared),
  );

  if (error || error2) {
    return (
      <ErrorBoundary
        height="60vh"
        variant="error"
        message="Failed to load performance data"
      />
    );
  }

  if (!data || !comparedData) {
    return (
      <Skeleton
        variant="rectangular"
        height={100}
        sx={(theme) => ({ borderRadius: `${theme.shape.borderRadius}px` })}
      />
    );
  }

  return (
    <ColorCard color={colors.darkBlue}>
      <CardHeader title="Channel performance comparison" />
      <CardContent>
        <CardTable
          performance={data}
          performanceCompared={comparedData}
          campaign={campaign}
          campaignCompared={compared}
        />
      </CardContent>
    </ColorCard>
  );
};

export default ChannelPerformance;
