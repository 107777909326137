import React, { ReactNode, useMemo } from 'react';
import { Grid, Stack } from '@mui/material';
import { DealTypes } from 'api';
import { getFormattedDate } from 'helpers/dayJsFormats';
import { colors } from 'theme/constants';
import { ShopInfo } from 'components';
import { formatPercent } from 'helpers/formatters';
import InfoCard from '../InfoCard';
import UrlsInfoCard from './UrlsInfoCard';
import ProductsInfo from './ProductsInfo';
import AttachmentUpload from './AttachmentUpload';
import LatestDealsTable from './LatestDealsTable';
import PrimaryMetrics from './PrimaryMetrics';
import StoryLinksInfoCard from './StoryLinksInfoCard';

interface Props {
  inline?: boolean;
  deal: DealTypes.ItemDataExtended;
}

export type DealInfoItems = {
  key: keyof DealTypes.ItemData | string;
  label: string;
  value: ReactNode;
}[];

const DetailsTab = (props: Props): JSX.Element | null => {
  const { deal, inline } = props;

  const discountInfo: DealInfoItems = useMemo(
    () => [
      {
        key: 'shop_id',
        label: 'Shop',
        value: <ShopInfo variant="text" shopId={deal.shop_id.$oid} />,
      },
      {
        key: 'coupon_code',
        label: 'Coupon code',
        value: deal.coupon_code,
      },
      {
        key: 'coupon_description',
        label: 'Coupon description',
        value: deal.coupon_description,
      },
      {
        key: 'discount_type',
        label: 'Discount type',
        value: deal.discount_type,
      },
      {
        key: 'discount_description',
        label: 'Percentage description',
        value: deal.discount_description,
      },
      {
        key: 'discount_value',
        label: 'Discount value',
        value: deal.discount_value,
      },
      {
        key: 'stories_tag',
        label: 'Stories tag',
        value: deal.stories_tag,
      },
    ],
    [
      deal.coupon_code,
      deal.coupon_description,
      deal.discount_description,
      deal.discount_type,
      deal.discount_value,
      deal.shop_id.$oid,
      deal.stories_tag,
    ],
  );

  const potentialBudgetInfo: DealInfoItems = useMemo(
    () => [
      {
        key: 'budget_type',
        label: 'Budget type',
        value: deal.budget_type,
      },
      {
        key: 'budget_potential_lc',
        label: 'Budget',
        value: deal.budget_potential_lc,
      },
      {
        key: 'budget_fixed',
        label: 'Budget fixed',
        value: deal.budget_fixed,
      },
      {
        key: 'roi_potential',
        label: 'ROI',
        value: deal.roi_potential,
      },
      {
        key: 'revenue_potential_lc',
        label: 'Revenue',
        value: deal.revenue_potential_lc,
      },
      {
        key: 'commission',
        label: 'Commission',
        value: deal.commission,
      },
      {
        key: 'gross_profit_potential',
        label: 'Gross profit',
        value: deal.gross_profit_potential,
      },
    ],
    [
      deal.budget_fixed,
      deal.gross_profit_potential,
      deal.budget_potential_lc,
      deal.budget_type,
      deal.commission,
      deal.revenue_potential_lc,
      deal.roi_potential,
    ],
  );

  const influencerInsightsInfo: DealInfoItems = useMemo(
    () => [
      {
        key: 'actual_reach',
        label: 'Actual reach',
        value: deal.actual_reach,
      },
      {
        key: 'avg_overall_reach',
        label: 'Avg overall reach',
        value: deal.avg_overall_reach,
      },
      {
        key: 'clicks',
        label: 'Clicks',
        value: deal.clicks,
      },
      {
        key: 'impressions',
        label: 'Impressions',
        value: deal.impressions,
      },
      {
        key: 'conversion_rate_pc',
        label: 'Conversion rate %',
        value: formatPercent(deal.conversion_rate_pc),
      },
    ],
    [
      deal.actual_reach,
      deal.avg_overall_reach,
      deal.clicks,
      deal.impressions,
      deal.conversion_rate_pc,
    ],
  );

  const mainInfo: DealInfoItems = useMemo(
    () => [
      {
        key: 'lead',
        label: 'Lead',
        value: deal.lead,
      },
      {
        key: 'strategy',
        label: 'Channel',
        value: deal.strategy,
      },
      {
        key: 'channel',
        label: 'Sub channel',
        value: deal.channel,
      },
      {
        key: 'focus',
        label: 'Focus',
        value: deal.focus,
      },
      {
        key: 'status',
        label: 'Status',
        value: deal.status,
      },
      {
        key: 'type',
        label: 'Type',
        value: deal.type,
      },
    ],
    [
      deal.channel,
      deal.focus,
      deal.lead,
      deal.status,
      deal.strategy,
      deal.type,
    ],
  );

  const otherInfo: DealInfoItems = useMemo(
    () => [
      {
        key: 'deal_id',
        label: 'Deal id',
        value: deal.deal_id,
      },
      {
        key: 'customer_reactivation_ratio',
        label: 'Reactivation rate',
        value: formatPercent(deal.customer_reactivation_ratio),
      },
      {
        key: 'start_date',
        label: 'Start date',
        value: getFormattedDate(deal.start_date),
      },
      {
        key: 'end_date',
        label: 'End date',
        value: deal.end_date ? getFormattedDate(deal.end_date) : '',
      },
      {
        key: 'reminder_date',
        label: 'Reminder date',
        value: deal.reminder_date ? getFormattedDate(deal.reminder_date) : '',
      },
      {
        key: 'created_at',
        label: 'Created at',
        value: getFormattedDate(deal.created_at),
      },
      {
        key: 'updated_at',
        label: 'Updated at',
        value: getFormattedDate(deal.updated_at),
      },
      {
        key: 'metrics_updated_at',
        label: 'Metrics updated at',
        value: getFormattedDate(deal.metrics_updated_at),
      },
    ],
    [deal],
  );

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <PrimaryMetrics deal={deal} inline={inline} />
      </Grid>
      <Grid item xs={12} md={inline ? 12 : 6} lg={inline ? 12 : 4}>
        <Stack rowGap={3}>
          <InfoCard
            title="General info"
            data={mainInfo}
            color={colors.darkOrange}
          />
          <InfoCard
            title="Potential budget info"
            data={potentialBudgetInfo}
            color={colors.darkGreen}
          />
          <InfoCard
            title="Influencer insights info"
            data={influencerInsightsInfo}
            color={colors.blue}
          />
        </Stack>
      </Grid>
      <Grid item xs={12} md={inline ? 12 : 6} lg={inline ? 12 : 4}>
        <Stack rowGap={3}>
          <UrlsInfoCard deal={deal} />
          <InfoCard
            title="Discount info"
            data={discountInfo}
            color={colors.darkBlue}
          >
            <ProductsInfo deal={deal} />
          </InfoCard>
          <StoryLinksInfoCard deal={deal} />
        </Stack>
      </Grid>
      <Grid item xs={12} md={inline ? 12 : 6} lg={inline ? 12 : 4}>
        <Stack rowGap={3}>
          <InfoCard
            title="Other info"
            data={otherInfo}
            color={colors.darkYellow}
          />
          <AttachmentUpload deal={deal} />
        </Stack>
      </Grid>
      <Grid item xs={12}>
        <LatestDealsTable data={deal} />
      </Grid>
    </Grid>
  );
};

export default DetailsTab;
