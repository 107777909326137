import React, { useState } from 'react';
import { Tabs, Tab, Stack } from '@mui/material';
import { InfluencerTypes } from 'api';
import AboutTab from './AboutTab';
import SimilarInfluencers from './SimilarInfluencers';
import InsightsTab from './InsightsTab';
import DealsTab from './DealsTab';
import ActivityLogTab from './ActivityLogTab';
import SidePanel from './PostsTab';
import LogbookTab from './LogbookTab';

const TABS = {
  deals: 'Deals',
  insights: 'Insights',
  about: 'About',
  activityLog: 'Activity log',
  posts: 'Posts',
  logbook: 'Logbook',
};

interface Props {
  inline?: boolean;
  data: InfluencerTypes.ItemExtended;
}

const Details = (props: Props): JSX.Element => {
  const { data, inline } = props;

  const [activeTab, setActiveTab] = useState(
    data.latest_deal ? TABS.deals : TABS.insights,
  );

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setActiveTab(newValue);
  };

  return (
    <>
      <Tabs value={activeTab} onChange={handleChange} variant="fullWidth">
        {data.latest_deal && <Tab label={TABS.deals} value={TABS.deals} />}
        <Tab label={TABS.insights} value={TABS.insights} />
        <Tab label={TABS.about} value={TABS.about} />
        <Tab label={TABS.activityLog} value={TABS.activityLog} />
        <Tab label={TABS.logbook} value={TABS.logbook} />
        {inline && <Tab label={TABS.posts} value={TABS.posts} />}
      </Tabs>
      <Stack rowGap={3} sx={{ mt: 3 }}>
        {activeTab === TABS.deals && <DealsTab data={data} />}
        {activeTab === TABS.insights && <InsightsTab data={data} />}
        {activeTab === TABS.about && <AboutTab data={data} inline={inline} />}
        {activeTab === TABS.activityLog && <ActivityLogTab data={data} />}
        {activeTab === TABS.logbook && <LogbookTab data={data} />}
        {activeTab === TABS.posts && <SidePanel data={data} inline />}
        <SimilarInfluencers data={data} />
      </Stack>
    </>
  );
};

export default Details;
