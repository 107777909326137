import React, { useState } from 'react';
import { Stack } from '@mui/material';
import { CampaignTypes } from 'api';
import ComparisonTable from './ComparisonTable';
import ChannelPerformance from './ChannelPerformance';
import SearchCard from './SearchCard';

interface Props {
  campaign: CampaignTypes.ItemData;
}

const DetailsTab = (props: Props): JSX.Element | null => {
  const { campaign } = props;
  const [compared, setCompared] = useState<
    CampaignTypes.ItemData | undefined
  >();

  return (
    <Stack rowGap={3}>
      <SearchCard
        campaign={campaign}
        compared={compared}
        setCompared={setCompared}
      />
      {compared && (
        <ChannelPerformance campaign={campaign} compared={compared} />
      )}
      <ComparisonTable campaign={campaign} comparedCampaign={compared} />
    </Stack>
  );
};

export default DetailsTab;
