import React, { useCallback, useMemo } from 'react';
import {
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import { formatCurrency, formatPercent } from 'helpers/formatters';
import { CampaignTypes } from 'api';
import { getSum } from 'helpers/calculations';

interface Props {
  performance: CampaignTypes.PerformanceInsightsVarData;
}

const config = [
  { key: 'strategy', label: 'Channel' },
  { key: 'sum_revenue_potential', label: 'Potential' },
  { key: 'sum_revenue_actual', label: 'Actual' },
  { key: 'variance', label: 'Variance' },
  { key: 'contribution_potential', label: 'Potential' },
  { key: 'contribution_actual', label: 'Actual' },
];

const CardTable: React.FC<Props> = ({ performance }) => {
  const { variance, sumRevenueActual, sumRevenuePotential } = useMemo(() => {
    const sumRevenueActual = getSum(
      performance.map((p) => p.sum_revenue_actual),
    );
    const sumRevenuePotential = getSum(
      performance.map((p) => p.sum_revenue_potential),
    );
    const variance = sumRevenueActual / sumRevenuePotential;

    return { sumRevenueActual, sumRevenuePotential, variance };
  }, [performance]);

  const getValue = useCallback(
    (item: CampaignTypes.PerformanceInsightsVarItem, c: (typeof config)[0]) => {
      switch (c.key) {
        case 'strategy':
          return item[c.key];
        case 'sum_revenue_actual':
        case 'sum_revenue_potential':
          return formatCurrency(item[c.key] as number);
        case 'variance':
          return formatPercent(item[c.key] || 0);
        case 'contribution_potential':
          return formatPercent(
            (item.sum_revenue_potential / sumRevenuePotential) * 100,
          );
        case 'contribution_actual':
          return formatPercent(
            (item.sum_revenue_actual / sumRevenueActual) * 100,
          );
        default:
          return '-';
      }
    },
    [sumRevenueActual, sumRevenuePotential],
  );

  const getSxProps = (index: number, theme: any) => ({
    borderRight:
      index === 0 || index === 3
        ? `1px solid ${theme.palette.divider}`
        : undefined,
  });

  return (
    <Card>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell
              colSpan={3}
              align="center"
              sx={(theme) => ({
                borderRight: `1px solid ${theme.palette.divider}`,
                borderLeft: `1px solid ${theme.palette.divider}`,
              })}
            >
              Revenue
            </TableCell>
            <TableCell colSpan={2} align="center">
              Contribution
            </TableCell>
          </TableRow>
          <TableRow>
            {config.map((c, index) => (
              <TableCell key={c.key} sx={(theme) => getSxProps(index, theme)}>
                {c.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {performance.map((item) => (
            <TableRow
              key={item.strategy}
              sx={{ '&:last-child td, &:last-child th': { borderBottom: 0 } }}
            >
              {config.map((c, index) => (
                <TableCell key={c.key} sx={(theme) => getSxProps(index, theme)}>
                  {getValue(item, c)}
                </TableCell>
              ))}
            </TableRow>
          ))}
          <TableRow
            sx={{ '&:last-child td, &:last-child th': { borderBottom: 0 } }}
          >
            <TableCell sx={(theme) => getSxProps(0, theme)}>Total</TableCell>
            <TableCell>{formatCurrency(sumRevenuePotential)}</TableCell>
            <TableCell>{formatCurrency(sumRevenueActual)}</TableCell>
            <TableCell sx={(theme) => getSxProps(3, theme)}>
              {formatPercent(variance)}
            </TableCell>
            <TableCell>-</TableCell>
            <TableCell>-</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Card>
  );
};

export default CardTable;
