import {
  ChartOption,
  ID,
  ListPaginationParams,
  ListPaginationResponse,
  AutocompleteSearchItem,
  LogbookItem,
} from '../types/common';
import {
  BrandBusinessFocusesEnum,
  BrandRelationsQualityEnum,
  BrandSalesPotentialEnum,
  BrandsEnum,
  BrandStatusEnum,
  BrandsTimeframeEnum,
} from '../types/Brands';
import { CountriesEnum } from '../types/Countries';
import { DealTypes } from '../deals';

namespace Influencers {
  // ------------- start enum ---------------

  export enum SizeEnum {
    MICRO = 'Micro',
    MACRO = 'Macro',
    MEGA = 'Mega',
    CELEBRITY = 'Celebrity',
  }

  export enum PlatformEnum {
    Instagram = 'Instagram',
    Pinterest = 'Pinterest',
    Snapchat = 'Snapchat',
    Tiktok = 'Tiktok',
    Youtube = 'Youtube',
  }

  export enum GenderEnum {
    Female = 'Female',
    Male = 'Male',
  }

  export enum AgeEnum {
    '0-16' = '< 16 yrs',
    '16-17' = '16-17 yrs',
    '18-21' = '18-21 yrs',
    '22-25' = '22-25 yrs',
    '26-29' = '26-29 yrs',
    '30-33' = '30-33 yrs',
    '34-37' = '34-37 yrs',
    '37+' = '> 37 yrs',
  }

  export enum VerticalsEnum {
    'Fashion' = 'Fashion',
    'High fashion' = 'High fashion',
    'Beauty' = 'Beauty',
    'Lifestyle' = 'Lifestyle',
    'Reality TV' = 'Reality TV',
    'Interior' = 'Interior',
    'Family' = 'Family',
    'Food' = 'Food',
    'Health / Mindfulness' = 'Health / Mindfulness',
    'Fitness' = 'Fitness',
    'Travel' = 'Travel',
    'Product reviews / Recommendations' = 'Product reviews / Recommendations',
    'Pets / Horses' = 'Pets / Horses',
    'Weight-loss' = 'Weight-loss',
    'Photography & art' = 'Photography & art',
    'Body-positivity' = 'Body-positivity',
    'LGBTQ+' = 'LGBTQ+',
    'Booklover' = 'Booklover',
    'Spirituality' = 'Spirituality',
    'TV Personality' = 'TV Personality',
    'Actor' = 'Actor',
    'Low waste / Zero waste' = 'Low waste / Zero waste',
    'Sustainability' = 'Sustainability',
  }

  // ------------- end enum ---------------

  export interface DailyKPI {
    date: string;
    sequences_nb: number;
    sequences_with_url: number;
    sequences_with_sound: number;
    sequences_with_video: number;
    mentions: string[];
  }

  export interface DailyKPI7DAvg {
    mentions_nb: number;
    sequences_nb: number;
    sequences_with_video: number;
    sequences_with_sound: number;
    sequences_with_url: number;
  }

  export interface TopStats {
    last_3_months: {
      [key: string]: number;
    };
    last_6_months: {
      [key: string]: number;
    };
    last_12_months: {
      [key: string]: number;
    };
    updated_at: string;
    last_3_months_nb_of_posts: number;
    last_6_months_nb_of_posts: number;
    last_12_months_nb_of_posts: number;
  }

  export interface Post {
    _id: ID;
    avg_budget: any;
    avg_comments_nb: any;
    avg_cpm: any;
    avg_gross_profit: any;
    avg_likes_nb: any;
    avg_revenue: any;
    avg_shares_nb: any;
    avg_views_nb: string | number;
    avg_views_nb_ts: ChartOption[];
    comment_to_like: any;
    comment_to_like_ts: ChartOption[];
    cpo: number;
    cpo_ts: ChartOption[];
    created_at: string;
    daily_kpis: DailyKPI[];
    daily_kpis_7d_avg: Partial<DailyKPI7DAvg>;
    engagement_rate_relative: number;
    engagement_rate_relative_ts: ChartOption[];
    engagement_rate: number;
    engagement_rate_ts: ChartOption[];
    followers_growth: number;
    followers_nb: number;
    followers_nb_ts: ChartOption[];
    followings_nb: number;
    is_private: boolean;
    latest_deal_budget: number;
    revenue_ts: ChartOption[];
    roi: number;
    roi_ts: ChartOption[];
    total_likes_nb: number;
    total_posts_nb: number;
    total_views_nb: number;
    type: string;
    updated_at: string;
    user_id: string;
    username: string;
    reach_rate: number;
    avg_link_clicks_nb: number;
  }

  export interface SocialNetwork {
    _id: ID;
    created_at: string;
    followers_growth: number;
    followers_nb: number;
    followers_nb_ts: ChartOption[];
    followings_nb: number;
    is_private: boolean;
    page_url: string;
    platform_last_updated_at: string;
    platform_not_found: boolean;
    platform_update_tries: number;
    type: PlatformEnum;
    updated_at: string;
    user_id: string;
    username: string;
  }

  export interface Instagram extends SocialNetwork {
    business_category_name: string;
    category_name: string;
    is_business_account: string;
  }

  export interface InstagramLatestPost {
    thumbnail: string;
    likes_nb: number;
    caption: string;
    link: string;
  }

  export interface PostsPost {
    _id: ID;
    caption: string;
    channel: string;
    clicks_nb: any;
    comment_to_like?: number | null;
    comments_nb?: number | null;
    created_at: string;
    date: string;
    day_part: string;
    deal_id: ID;
    dislikes_nb?: number | null;
    engagement_rate_relative?: number | null;
    influencer_profile_name: string;
    instagram_post_id: ID | null;
    instagram_reel_id: ID | null;
    instagram_story_id: ID | null;
    instagram_tv_id: ID | null;
    likes_nb: number;
    reach: number | null;
    reminded_deal_id: ID | null;
    sequence_ids: string[] | null;
    shares_nb: number | null;
    shortcode: string;
    source_url: string;
    stories_tag: any;
    tags: string[];
    tiktok_post_id: ID | null;
    updated_at: string;
    views_nb: number | null;
    thumbnail_url: string;
  }

  export interface InstagramPost extends Post {
    avg_cpo: number;
    avg_roi: number;
    latest_posts: Partial<InstagramLatestPost>[];
    posts: Partial<PostsPost>[];
  }

  export interface InstagramReel extends Post {}

  export interface InstagramStory extends Post {
    avg_cpo: number;
    latest_posts: any[];
  }

  export interface InstagramTV extends Post {}

  export interface Pinterest extends SocialNetwork {}

  export interface PinterestPost extends Post {}

  export interface TikTok extends SocialNetwork {}

  export interface TikTokPost extends Post {
    posts: Partial<PostsPost>[];
  }

  export interface YouTube extends SocialNetwork {}

  export interface YouTubePost extends Post {}

  export interface Snapchat extends SocialNetwork {}

  export interface SnapchatPost extends Post {
    latest_posts: any[];
  }

  // ------------- start create params ---------------

  export interface PersonalInfoCreateParams {
    age_group: AgeEnum;
    birth_date: string;
    email: string;
    first_name: string;
    gender: GenderEnum;
    last_name: string;
    middle_name: string;
    phone: string;
    private_address: string;
    shipping_address: string;
  }

  export interface AgencyInfoCreateParams {
    agency_name: string;
    contact_name: string;
    email: string;
    phone: string;
  }

  export interface AudienceInfoItem {
    type: 'age_group' | 'country' | 'gender';
    value_percent: number;
  }

  export interface AudienceInfoCreateParams {
    [key: string]: AudienceInfoItem;
  }

  export interface BrandInfoCreateParams {
    brand: BrandsEnum;
    status: BrandStatusEnum;
    country_team: CountriesEnum;
    lead?: string;
    timeframe?: BrandsTimeframeEnum;
    relations_quality?: BrandRelationsQualityEnum;
    sales_potential?: BrandSalesPotentialEnum;
    business_focuses?: BrandBusinessFocusesEnum[];
    comments?: string;
  }

  export interface ImportantDateCreateParam {
    date: string;
    label: string;
    reminder_date: string;
  }

  export interface ItemCreateParams {
    country_for_brand_mentions?: CountriesEnum;
    enable_brand_mentions?: boolean;

    personal_info: PersonalInfoCreateParams;
    tags: string[];
    target_countries: CountriesEnum[];
    notes: string;
    vertical_primary: VerticalsEnum;
    verticals_secondary: VerticalsEnum[];
    agency_contact_info: AgencyInfoCreateParams;
    audience_info: AudienceInfoCreateParams;
    brand_infos: BrandInfoCreateParams[];
    important_dates: ImportantDateCreateParam[];
    instagram: Pick<SocialNetwork, 'username'>;
    instagram_post: Pick<Post, 'avg_views_nb'>;
    instagram_story: Pick<Post, 'avg_views_nb' | 'avg_link_clicks_nb'>;
    tiktok: Pick<SocialNetwork, 'username'>;
    tiktok_post: Pick<Post, 'avg_views_nb'>;
    pinterest: Pick<SocialNetwork, 'username'>;
    pinterest_post: Pick<Post, 'avg_views_nb'>;
    snapchat: Pick<SocialNetwork, 'username'>;
    snapchat_post: Pick<Post, 'avg_views_nb'>;
    youtube: Pick<SocialNetwork, 'page_url' | 'username'>;
    youtube_post: Pick<Post, 'avg_views_nb'>;
  }

  // ------------- end create params ---------------

  export type PersonalInfo = Partial<PersonalInfoCreateParams> & { _id: ID };

  export type AudienceInfo = Partial<AudienceInfoCreateParams> & { _id: ID };

  export type AgencyInfo = Partial<AgencyInfoCreateParams> & { _id: ID };

  export type BrandInfo = Partial<BrandInfoCreateParams> & {
    _id: ID;
  };

  export interface PipelinePreparationItem {
    avg_gross_profit: number | null;
    avg_revenue: number;
    best_post: { date: string; budget_potential: number };
    cpo: number;
    deals: DealTypes.PipelinePreparationItem[];
    influencer_id: string;
    past_deals_nb: number;
    past_deals_nb_30_d: number;
    past_deals_nb_60_d: number;
    planned_deals_nb: number;
    profile_name: string;
    roi: number;
    total_gross_profit: number;
    total_revenue: number;
    total_spent: number;
    _id: string;
  }

  export interface ItemCondensed {
    avg_gross_profit: number | null;
    avg_revenue: number | null;
    deals_nb: number;
    total_gross_profit: number;
    total_revenue: number;
    total_spent: number;

    _id: ID;
    bookmark_id: string | null;
    cpo: number | null;
    cpo_ts: ChartOption[];
    influencer_id: string;
    is_verified: boolean;
    personal_info: Partial<PersonalInfo>;
    platform_types: PlatformEnum[];
    profile_name: string | null;
    profile_picture_url: string | null;
    revenue_ts: ChartOption[];
    roi: number | null;
    roi_ts: ChartOption[];
    size: SizeEnum | null;
    target_countries: CountriesEnum[];
    brand_infos: BrandInfo[];

    instagram?: Partial<Instagram>;
    instagram_post?: Partial<InstagramPost>;
    instagram_reel?: Partial<InstagramReel>;
    instagram_story?: Partial<InstagramStory>;
    instagram_tv?: Partial<InstagramTV>;
    tiktok?: Partial<TikTok>;
    tiktok_post?: Partial<TikTokPost>;
    youtube?: Partial<YouTube>;
    youtube_post?: Partial<YouTubePost>;
    snapchat?: Partial<Snapchat>;
    snapchat_post?: Partial<SnapchatPost>;
    pinterest?: Partial<Pinterest>;
    pinterest_post?: Partial<PinterestPost>;
  }

  export interface Item {
    _id: ID;
    added_for_brand_mentions_at: string;
    country_for_brand_mentions: CountriesEnum;
    enable_brand_mentions: boolean;
    removed_from_brand_mentions_at: string;

    bookmark_id: string | null;
    avg_revenue: number | null;
    agency_contact_info?: AgencyInfo;
    audience_info: AudienceInfo;
    avg_budget: number;
    avg_cpm: number;
    avg_cr: number | null;
    avg_gross_profit: number;
    biography: string;
    biography_email: any;
    brand_infos: BrandInfo[];
    brand_mentions: any[];
    categories: string[];
    channel_types: string[];
    cpo: number | null;
    created_at: string;
    global_search_string: string;
    important_dates: ImportantDateCreateParam[];
    influencer_id: string;
    is_brand: boolean;
    is_deleted: boolean;
    is_verified: boolean;
    notes: string;
    personal_info: Partial<PersonalInfo>;
    platform_types: PlatformEnum[];
    profile_name: string;
    roi: number | null;
    size: SizeEnum;
    stories_checked_for_mentions_at: string;
    tags: string[];
    target_countries: CountriesEnum[];
    top_hashtags: TopStats;
    top_mentions: TopStats;
    type: string;
    updated_at: string;
    vertical_primary: VerticalsEnum;
    verticals_secondary: VerticalsEnum[];
    latest_deal: Partial<DealTypes.ItemData>;
    profile_picture_url: string;
    recently_created_deals: any[];
    pipeline_preparation: PipelinePreparationItem;

    budget_ts: ChartOption[];
    cpo_ts: ChartOption[];
    cr_ts: ChartOption[];
    revenue_ts: ChartOption[];
    roi_ts: ChartOption[];

    instagram: Partial<Instagram>;
    instagram_post: Partial<InstagramPost>;
    instagram_reel: Partial<InstagramReel>;
    instagram_story: Partial<InstagramStory>;
    instagram_tv: Partial<InstagramTV>;
    tiktok: Partial<TikTok>;
    tiktok_post: Partial<TikTokPost>;
    youtube: Partial<YouTube>;
    youtube_post: Partial<YouTubePost>;
    snapchat: Partial<Snapchat>;
    snapchat_post: Partial<SnapchatPost>;
    pinterest: Partial<Pinterest>;
    pinterest_post: Partial<PinterestPost>;
  }

  export type PlatformType = keyof Pick<
    Item,
    'instagram' | 'tiktok' | 'youtube' | 'pinterest' | 'snapchat'
  >;

  export type PlatformPostType = keyof Pick<
    Item,
    | 'instagram_post'
    | 'tiktok_post'
    | 'youtube_post'
    | 'pinterest_post'
    | 'snapchat_post'
  >;

  export interface ItemData extends Partial<Item> {
    _id: ID;
    influencer_id: string;
  }

  export interface ItemExtended extends ItemData {
    can_delete: boolean;
    related_influencers: ItemData[];
    avg_revenue: number | null;
    deals_nb: number;
    total_gross_profit: number;
    total_revenue: number;
    total_spent: number;
  }

  export type ItemResponse = Promise<ItemExtended>;

  export type ListCondensedData = ListPaginationResponse<ItemCondensed>;

  export type ListCondensedResponse = Promise<ListCondensedData>;

  export type ListData = ListPaginationResponse<ItemData>;

  export type ListResponse = Promise<ListData>;

  export interface GetListParams extends ListPaginationParams {
    response_format?: string | 'condensed';
    filters: {
      global_search_string?: string;
      is_verified?: boolean;
      tags?: string[];
      'brand_infos.status'?: string[];
      'brand_infos.lead'?: string[];
      size?: SizeEnum[];
      platform_types?: PlatformEnum[];
      'personal_info.gender'?: GenderEnum[];
      'personal_info.age_group'?: AgeEnum[];
      'personal_info.email'?: string;
      vertical_primary?: VerticalsEnum[];
      target_countries?: CountriesEnum[];
      no_deal_in?: string;
      show_bookmarks?: boolean;
      enable_brand_mentions?: boolean;
      country_for_brand_mentions?: CountriesEnum;
    };
  }

  export type DealsChartType =
    | 'roi'
    | 'cpo'
    | 'cpm'
    | 'revenue'
    | 'budget'
    | 'cr';

  export type DealsChartData = {
    [key in DealsChartType]: Record<string, number>;
  } & {
    global_avg_details: Record<string, number>;
  };

  export type DealsChartResponse = Promise<DealsChartData>;

  export type InsightsChartType =
    | 'engagement_rate'
    | 'followers'
    | 'likes'
    | 'comments'
    | 'comments_to_likes'
    | 'avg_views_nb';

  export type InsightsChartPlatformType = 'instagram' | 'youtube' | 'tiktok';

  export type InsightsChartDataKey =
    | 'engagement_rate'
    | 'followers'
    | 'avg_likes'
    | 'avg_comments'
    | 'comments_to_likes'
    | 'avg_views_nb';

  export type InsightsChartData = {
    [key in InsightsChartDataKey]: Record<string, number>;
  } & {
    global_avg_details: Record<string, number>;
  };

  export type InsightsChartResponse = Promise<InsightsChartData>;

  export type AutocompleteSearchData = AutocompleteSearchItem[];

  export type AutocompleteSearchResponse = Promise<AutocompleteSearchData>;

  export type AutocompleteSearchParams =
    | string
    | {
        global_search_string: string;
        platform_type?: PlatformEnum;
      };

  export interface ItemAutoUpdatedData {
    influencer_id: ID;
    old_username: string;
    new_username: string;
    created_at: string;
  }

  export type ListAutoUpdatedData = ItemAutoUpdatedData[];

  export type ListAutoUpdatedResponse = Promise<{
    data: ListAutoUpdatedData;
    total: number;
  }>;

  export interface PotentialDetailsData {
    am_based_avg_budget: number;
    am_potential_roi: number;
    faynt_based_avg_budget: number;
    faynt_potential_roi: number;
    cn_based_avg_budget: number;
    cn_potential_roi: number;
    influencer_id: string;
  }

  export type PotentialDetailsResponse = Promise<PotentialDetailsData>;

  export interface BulkBrandMentionParams {
    influencer_ids: string[];
    enable_brand_mentions: boolean;
    country_for_brand_mentions?: CountriesEnum;
  }

  export interface TopInfluencerItemData {
    avg_gross_profit: number | null;
    avg_revenue: number;
    best_post: { date: string; budget_potential: number };
    cpo: number;
    deals: DealTypes.PipelinePreparationItem[];
    influencer_id: ID;
    past_deals_nb: number;
    past_deals_nb_30_d: number;
    past_deals_nb_60_d: number;
    planned_deals_nb: number;
    profile_name: string;
    roi: number;
    total_gross_profit: number;
    total_revenue: number;
    total_spent: number;
    _id: ID;
  }

  export type TopInfluencersListData =
    ListPaginationResponse<TopInfluencerItemData>;

  export type TopInfluencersListResponse = Promise<TopInfluencersListData>;

  export interface GetTopInfluencersListParams extends ListPaginationParams {
    limit: number;
    page: number;
    sortBy?: string;
    sortDesc?: boolean;
    filters: {
      no_deal_in?: string;
    };
  }

  export type LogbookData = LogbookItem<ItemData>[];

  export type LogbookResponse = Promise<LogbookData>;
}

export default Influencers;
