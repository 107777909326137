import { useQuery } from '@tanstack/react-query';
import { LogbookItem } from 'api/types/common';
import LogsTableLoader from './LogsTableLoader';
import LogsTable from './LogsTable';

interface Props {
  queryKey: string;
  queryFn: () => Promise<LogbookItem<Record<string, unknown>>[]>;
}

const LogbookTab = (props: Props): JSX.Element | null => {
  const { queryKey, queryFn } = props;

  const { data: logs } = useQuery([queryKey], queryFn);

  if (!logs) return <LogsTableLoader />;

  return <LogsTable items={logs} />;
};

export default LogbookTab;
