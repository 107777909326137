import React, { useMemo } from 'react';
import { Grid, Stack, Typography } from '@mui/material';
import { CampaignTypes } from 'api';
import { getFormattedDate } from 'helpers/dayJsFormats';
import { colors } from 'theme/constants';
import { InfoCard, InfoCardItem } from 'components';
import { formatCurrency, formatPercent } from 'helpers/formatters';
import CampaignComparisonCard from './CampaignComparisonCard';

interface Props {
  inline?: boolean;
  campaign: CampaignTypes.ItemData;
}

const DetailsTab = (props: Props): JSX.Element | null => {
  const { campaign, inline } = props;

  const generalInfo: InfoCardItem<CampaignTypes.ItemData>[] = useMemo(
    () => [
      {
        key: 'brands',
        label: 'Brands',
        value: campaign.brands.join(', '),
      },
      {
        key: 'country_teams',
        label: 'Countries',
        value: campaign.country_teams.join(', '),
      },
      {
        key: 'status',
        label: 'Status',
        value: campaign.status,
      },
      {
        key: 'type',
        label: 'Type',
        value: campaign.type,
      },
    ],
    [campaign.brands, campaign.country_teams, campaign.status, campaign.type],
  );

  const performanceInfo: InfoCardItem<CampaignTypes.ItemData>[] = useMemo(
    () => [
      {
        key: 'budget_actual',
        label: 'Budget',
        value: formatCurrency(campaign.budget_actual),
      },
      {
        key: 'aov_actual',
        label: 'AOV',
        value: formatCurrency(campaign.aov_actual),
      },
      {
        key: 'revenue_actual',
        label: 'Revenue',
        value: formatCurrency(campaign.revenue_actual),
      },
      {
        key: 'deals_nb',
        label: 'Deals',
        value: campaign.deals_nb,
      },
      {
        key: 'influencers_nb',
        label: 'Influencers',
        value: campaign.influencers_nb,
      },
      {
        key: 'new_customers_actual',
        label: 'New customers',
        value: formatPercent(campaign.new_customers_actual),
      },
      {
        key: 'orders_nb_actual',
        label: 'Orders',
        value: campaign.orders_nb_actual,
      },
      {
        key: 'reach_total',
        label: 'Reach',
        value: campaign.reach_total,
      },
      {
        key: 'customer_reactivation_ratio',
        label: 'Reactivation rate',
        value: formatPercent(campaign.customer_reactivation_ratio),
      },
    ],
    [campaign],
  );

  const otherInfo: InfoCardItem<CampaignTypes.ItemData>[] = useMemo(
    () => [
      {
        key: 'start_date',
        label: 'Start date',
        value: getFormattedDate(campaign.start_date),
      },
      {
        key: 'end_date',
        label: 'End date',
        value: campaign.end_date ? getFormattedDate(campaign.end_date) : '',
      },
      {
        key: 'created_at',
        label: 'Created at',
        value: getFormattedDate(campaign.created_at),
      },
      {
        key: 'updated_at',
        label: 'Updated at',
        value: getFormattedDate(campaign.updated_at),
      },
    ],
    [
      campaign.created_at,
      campaign.end_date,
      campaign.start_date,
      campaign.updated_at,
    ],
  );

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={inline ? 12 : 6}>
        <Stack rowGap={3}>
          <InfoCard
            title="Performance"
            data={performanceInfo}
            color={colors.darkGreen}
          />
          {campaign.description && (
            <InfoCard title="Description" color={colors.green2}>
              <Typography>{campaign.description}</Typography>
            </InfoCard>
          )}
          <InfoCard
            title="General info"
            data={generalInfo}
            color={colors.darkOrange}
          />
        </Stack>
      </Grid>
      <Grid item xs={12} md={inline ? 12 : 6}>
        <Stack rowGap={3}>
          <InfoCard title="Other info" data={otherInfo} color={colors.blue} />
          <CampaignComparisonCard campaign={campaign} />
        </Stack>
      </Grid>
    </Grid>
  );
};

export default DetailsTab;
