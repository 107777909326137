import {
  ID,
  ListPaginationParams,
  ListPaginationResponse,
} from '../types/common';
import { CountriesEnum } from '../types/Countries';
import { BrandsEnum } from '../types/Brands';
import { DealTypes } from '../deals';

namespace CampaignTypes {
  export enum StatusEnum {
    Pending = 'Pending',
    Ongoing = 'Ongoing',
    Finished = 'Finished',
  }

  export enum TypeEnum {
    BrandLaunch = 'Brand launch',
    CRM = 'CRM',
    FestiveSeason = 'Festive season',
    FlashSale = 'Flash sale',
    PerformanceMarketing = 'Performance marketing',
    ProductLaunch = 'Product launch',
    Branding = 'Branding',
  }

  export interface ItemCreateParams {
    name: string;
    status: StatusEnum;
    type: TypeEnum;
    brands: BrandsEnum[];
    country_teams: CountriesEnum[];
    start_date: string;
    end_date: string;
    description?: string;
    picture_url?: string | null;
  }

  export interface ItemData extends ItemCreateParams {
    _id: ID;
    aov_actual: number;
    aov_target?: number;
    budget_actual: number;
    budget_distribution: any;
    budget_potential: number;
    budget_running_forecast: number;
    budget_target?: number;
    budget_traffic_light?: number;
    campaign_id: string;
    created_at: string;
    deal_ids: ID[];
    deals_nb: number;
    influencers_nb: number;
    is_deleted: boolean;
    new_customers_actual?: number;
    new_customers_target?: number;
    orders_nb_actual: number;
    reach_total: number;
    revenue_actual: number;
    revenue_potential: number;
    revenue_running_forecast: number;
    revenue_target?: number;
    revenue_traffic_light?: number;
    roi_target: number;
    updated_at: string;
    customer_reactivation_ratio: number | null;
  }

  export type ItemResponse = Promise<ItemData>;

  export type ListData = ListPaginationResponse<ItemData>;

  export type ListResponse = Promise<ListData>;

  export interface GetListParams extends Partial<ListPaginationParams> {
    // global
    filters: {
      country_teams?: string | string[];
      brands?: string | string[];
      // general
      _id?: string[];
      name?: string;
      status?: StatusEnum[];
      type?: TypeEnum;
      start_date?: {
        $gte?: string;
        $lte: string;
      };
    };
  }

  export interface AutocompleteSearchParams {
    name: string;
    status?: StatusEnum;
  }

  export type AutocompleteSearchItem = Pick<ItemData, '_id' | 'name'>;

  export type AutocompleteSearchData = AutocompleteSearchItem[];

  export type AutocompleteSearchResponse = Promise<AutocompleteSearchData>;

  export interface PerformanceDetailsItem {
    budget_actual: number;
    budget_potential: number;
    revenue_running_forecast: number;
    revenue_actual: number;
    revenue_potential: number;
    budget_running_forecast: number;
    orders_nb_actual: number;
    gross_profit_actual: number;
    key: string; // date
    roi_actual?: number;
    roi_potential: number;
  }

  export interface PerformanceSummary {
    budget_target: number;
    budget_actual: number;
    budget_potential: number;
    budget_running_forecast: number;
    budget_traffic_light?: number;
    revenue_target: number;
    revenue_actual: number;
    revenue_potential: number;
    revenue_running_forecast: number;
    revenue_traffic_light?: number;
    orders_nb_actual: number;
    roi_actual?: number;
    roi_potential: number;
  }

  export interface PerformanceData {
    summary: PerformanceSummary;
    details: PerformanceDetailsItem[];
  }

  export type PerformanceResponse = Promise<PerformanceData>;

  export interface PerformanceParams {
    filters: {
      campaign_ids: string[];
      channel?: DealTypes.ChannelEnum[];
      strategy?: DealTypes.StrategiesEnum[];
      type?: DealTypes.TypeEnum;
      country_team?: CountriesEnum[];
      brand?: BrandsEnum[];
    };
    period: {
      start_date: string;
      end_date: string;
    };
  }

  export interface PerformanceChartItem {
    label: string;
    values: { key: string; value: number | null }[];
  }

  export type PerformanceChartData = PerformanceChartItem[];

  export interface PerformanceInfluencerItem {
    deal_id: string;
    influencer_profile_name: string;
    roi_actual: number;
    sum_revenue_actual: number;
  }

  export type PerformanceInfluencerData = PerformanceInfluencerItem[];

  export interface PerformanceInsightsVarItem {
    strategy: DealTypes.StrategiesEnum;
    sum_revenue_actual: number;
    sum_revenue_potential: number;
    variance: number;
  }

  export type PerformanceInsightsVarData = PerformanceInsightsVarItem[];

  export interface PerformanceInsightsInfluencersItem {
    count_deals: number;
    deals_done: number;
    rev_per_post: number;
    sum_budget_actual: number;
    sum_budget_potential: number;
    sum_revenue_actual: number;
    sum_revenue_potential: number;
    ttl_roi_actual: number;
    ttl_roi_potential: number;
    type: DealTypes.TypeEnum;
  }

  export type PerformanceInsightsInfluencersData =
    PerformanceInsightsInfluencersItem[];
}

export default CampaignTypes;
