import React, { ReactNode } from 'react';
import {
  CardContent,
  CardHeader,
  Chip,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { TrendingDownRounded, TrendingUpRounded } from '@mui/icons-material';
import { TooltipChip, ColorCard } from 'components';

export type ComparisonTableItems<T = any> = {
  key: keyof T & string;
  label: string;
  prevValue: ReactNode;
  currentValue: ReactNode;
  trend: 'up' | 'down';
}[];

interface Props {
  data: ComparisonTableItems;
  loading?: boolean;
  currentTitle?: string;
  comparedTitle?: string;
  color: string;
  title: string;
}

const CardTable = (props: Props): JSX.Element | null => {
  const { data, loading, comparedTitle, currentTitle, title, color } = props;

  return (
    <ColorCard color={color}>
      <CardHeader title={title} />
      <CardContent
        sx={{
          // maxHeight: '70vh',
          overflowY: 'auto',
          pt: 0,
        }}
      >
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell align="left">Metric</TableCell>
              <TableCell align="right">
                <Chip
                  size="small"
                  variant="outlined"
                  color="primary"
                  label={currentTitle || 'Current'}
                />
              </TableCell>
              <TableCell align="right">
                <Chip
                  size="small"
                  variant="outlined"
                  color="secondary"
                  label={comparedTitle || 'Compared'}
                />
              </TableCell>
              <TableCell align="right">Trend</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <>
                {data.map((row) => (
                  <TableRow
                    key={row.key}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      <Skeleton variant="text" />
                    </TableCell>
                    <TableCell align="right">
                      <Skeleton variant="text" />
                    </TableCell>
                    <TableCell align="right">
                      <Skeleton variant="text" />
                    </TableCell>
                    <TableCell align="right">
                      <Skeleton
                        variant="rectangular"
                        sx={(theme) => ({
                          borderRadius: `${theme.shape.borderRadius}px`,
                        })}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </>
            ) : (
              <>
                {data.map((row) => (
                  <TableRow
                    key={row.key}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {row.label}
                    </TableCell>
                    <TableCell align="right">
                      <Typography color="text.secondary" variant="inherit">
                        {row.currentValue}
                      </Typography>
                    </TableCell>
                    <TableCell align="right">
                      <Typography color="text.secondary" variant="inherit">
                        {row.prevValue}
                      </Typography>
                    </TableCell>
                    <TableCell align="right">
                      <TooltipChip
                        title="Trend"
                        color={row.trend === 'up' ? 'success' : 'warning'}
                        sx={{
                          '& span': {
                            display: 'flex',
                            padding: 1,
                          },
                        }}
                        label={
                          row.trend === 'up' ? (
                            <TrendingUpRounded />
                          ) : (
                            <TrendingDownRounded />
                          )
                        }
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </>
            )}
          </TableBody>
        </Table>
      </CardContent>
    </ColorCard>
  );
};

export default CardTable;
