import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Card,
  Chip,
  Stack,
  Tooltip,
} from '@mui/material';
import {
  EditNoteOutlined,
  ExpandMore,
  InfoOutlined,
} from '@mui/icons-material';
import { getFormattedDateTime } from 'helpers/dayJsFormats';
import { LogbookItem } from 'api/types/common';

interface Props {
  items: LogbookItem<Record<string, unknown>>[];
}

const formatKey = (key: string) =>
  key
    .replace(/([a-z0-9])([A-Z])/g, '$1 $2') // split camelCase
    .replace(/_/g, ' ') // replace underscores with spaces
    .replace(/([a-zA-Z])(\d)/g, '$1 $2') // split camelCase with numbers
    .replace(/\b\w/g, (char) => char.toUpperCase()); // capitalize first letter of each word

const renderChangeValue = (value: any) => {
  if (Array.isArray(value)) {
    return value.length > 0 ? value.join(', ') : 'Empty array';
  }
  if (typeof value === 'object' && value !== null) {
    return Object.keys(value).length > 0
      ? JSON.stringify(value, null, 2)
      : 'Empty object';
  }

  const v = value !== null && value !== undefined ? value : 'N/A';
  return typeof v === 'boolean' ? JSON.stringify(v) : v;
};

const LogbookTable = (props: Props) => {
  const { items } = props;

  const sortedItems = [...items].sort(
    (a, b) =>
      new Date(b.created_at).getTime() - new Date(a.created_at).getTime(),
  );

  return (
    <TableContainer component={Card}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell>Created At</TableCell>
            <TableCell>Modifier</TableCell>
            <TableCell>Changes</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedItems.map((item) => (
            <TableRow
              key={item.created_at}
              selected={item.modifier === 'IMS Hashtag You'}
            >
              <TableCell>
                {item.modifier === 'IMS Hashtag You' ? (
                  <Tooltip title="Changes were made by IMS system">
                    <InfoOutlined />
                  </Tooltip>
                ) : (
                  <Tooltip title="Changes were made by user">
                    <EditNoteOutlined />
                  </Tooltip>
                )}
              </TableCell>
              <TableCell>{getFormattedDateTime(item.created_at)}</TableCell>
              <TableCell>{item.modifier}</TableCell>
              <TableCell>
                <Accordion>
                  <AccordionSummary expandIcon={<ExpandMore />}>
                    <Stack direction="row" columnGap={2} alignItems="center">
                      <Typography>View Changes</Typography>
                      <Tooltip title="Changes count">
                        <Chip
                          label={Object.keys(item.changes).length}
                          color="primary"
                          size="small"
                        />
                      </Tooltip>
                    </Stack>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div>
                      {Object.keys(item.changes).map((k) => {
                        const key = k as keyof typeof item.changes;
                        return (
                          <Typography key={key} gutterBottom>
                            <strong>{formatKey(key)}</strong>: from{' '}
                            <Typography component="span" color="error">
                              {renderChangeValue(
                                item.changes[key]
                                  ? item.changes[key]?.from
                                  : undefined,
                              )}
                            </Typography>
                            {' to '}
                            <Typography
                              component="span"
                              sx={(t) => ({ color: t.palette.primary.main })}
                            >
                              {renderChangeValue(
                                item.changes[key]
                                  ? item.changes[key]?.to
                                  : undefined,
                              )}
                            </Typography>
                          </Typography>
                        );
                      })}
                    </div>
                  </AccordionDetails>
                </Accordion>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {sortedItems.length === 0 && (
        <Typography
          variant="h5"
          align="center"
          color="textPrimary"
          sx={{ m: (theme) => theme.spacing(5) }}
        >
          No records found
        </Typography>
      )}
    </TableContainer>
  );
};

export default LogbookTable;
